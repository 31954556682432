<template>
  <div class="input-container">
    <label
      class="input-label"
      :style="{
        background: `linear-gradient(180deg, ${bg_color} 0%, ${bg_color} 45%, white 45.1%, white 100%)`,
      }"
      :class="{ activated: modelValue.length > 0 }"
    >
      {{ placeholder }}
    </label>
    <input
      :value="modelValue"
      class="input__class"
      :placeholder="placeholder"
      @input="onChanged"
      :disabled="disabled"
      :required="required"
      :min="min"
      :type="visible_password ? 'text' : 'password'"
    />
    <img
      @click="visible_password = !visible_password"
      v-if="visible_password"
      style="
        padding-left: 10px;
        cursor: pointer;
        position: absolute;
        right: 10px;
        width: 35px;
      "
      src="@/assets/rebrand_icons/action/visibility.svg"
      alt="ojo"
    />
    <img
      @click="visible_password = !visible_password"
      v-if="!visible_password"
      style="
        padding-left: 10px;
        cursor: pointer;
        position: absolute;
        right: 10px;
        width: 35px;
      "
      src="@/assets/rebrand_icons/action/visibility_off.svg"
      alt="ojo_cerrado"
    />
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue";

defineProps({
  modelValue: String,
  placeholder: {
    type: String,
    default: "",
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: "text",
  },
  bg_color: {
    type: String,
    default: "white",
  },
  min: {
    type: Number,
    default: 0,
  },
})
const emits = defineEmits(["update:modelValue", "enterPressed"]);

const visible_password = ref(false);

function onChanged(e) {
  emits("update:modelValue", e.currentTarget.value);
}
</script>

<style scoped>
.input-container {
  display: flex;
  align-items: center;
}
</style>
