<template>
  <div class="fondo_impresion_invoice">
    <div class="invoice_title" v-if="customerOrder.local.has_invoice_quickeat">
      {{ customerOrder.local.business_tax_name }}
    </div>
    <div class="invoice_title" v-if="customerOrder.local.has_invoice_quickeat">
      {{ customerOrder.local.legal_address }}
    </div>
    <div class="invoice_title" v-if="customerOrder.local.has_invoice_quickeat">
      Ruc: {{ customerOrder.local.business_tax_id }}
    </div>
    <div
        style="width: 100%; border-bottom: dashed"
        v-if="customerOrder.local.has_invoice_quickeat"
    ></div>
    <div v-if="customerOrder.local.has_invoice_quickeat">
      Cliente: {{ customerInvoice() }}
    </div>
    <div
        v-if="
        customerOrder.local.has_invoice_quickeat &&
        customerOrder.ruc !== '' &&
        customerOrder.ruc != null
      "
    >
      Documento: {{ customerOrder.ruc }}
    </div>
    <div style="width: 100%; border-bottom: dashed"></div>
    <div class="invoice_title" v-if="customerOrder.local.has_invoice_quickeat">
      {{ title_invoice() }}
    </div>
    <div
        style="width: 100%; border-bottom: dashed"
        v-if="customerOrder.local.has_invoice_quickeat"
    ></div>
    <div>Fecha: {{ hora_pedido(customerOrder.order_date) }}</div>
    <table class="table_products_print_invoice">
      <tr class="table_header_print_invoice">
        <th class="column_cantidad_print_invoice" style="width: 10%">Cant.</th>
        <th style="width: 70%">Producto</th>
        <th class="column_subtotal_print_invoice" style="width: 20%">S/.</th>
      </tr>
      <tr
          v-for="(item, key) in order_detail_temp_obj"
          :key="key"
          style="border-bottom: dashed; border-bottom-color: black"
      >
        <td class="item_cantidad_cont_print_invoice">
          {{ item.cant }}
        </td>
        <td class="info_print_invoice">
          <p style="margin-bottom: 0">{{ item.product_name }}</p>
          <ul style="margin-bottom: 0">
            <li v-for="size in item.product_size_names" :key="size">
              a: {{ size }}
            </li>
            <li
                v-if="
                item.product_sizes2_names !== null &&
                item.product_sizes2_names.length > 0
              "
            >
              <ul>
                <li v-for="size in item.product_sizes2_names" :key="size">
                  b: {{ size }}
                </li>
              </ul>
            </li>
            <li
                v-if="
                item.product_sizes3_names !== null &&
                item.product_sizes3_names.length > 0
              "
            >
              <ul>
                <li v-for="size in item.product_sizes3_names" :key="size">
                  c: {{ size }}
                </li>
              </ul>
            </li>
            <li
                v-if="
                item.product_sizes4_names !== null &&
                item.product_sizes4_names.length > 0
              "
            >
              <ul>
                <li v-for="size in item.product_sizes4_names" :key="size">
                  d: {{ size }}
                </li>
              </ul>
            </li>
            <li v-if="item.ingredients_add.length > 0 && item.product_id !== 0">
              Extras:
              <ul>
                <li
                    v-for="ingredient in item.ingredients_add"
                    :key="ingredient"
                >
                  - {{ ingredient }}
                </li>
              </ul>
            </li>
          </ul>
          <p style="margin-bottom: 0" v-if="item.comments">
            Obs: {{ item.comments }}
          </p>
        </td>
        <td style="text-align: end; width: 20%">
          {{ parseFloat(item.subtotal).toFixed(2) }}
        </td>
      </tr>
      <tr
          v-if="
          customerOrder.type_delivery === '3' &&
          parseFloat(customerOrder.delivery) > 0 &&
          customerOrder.local.has_taxable_delivery
        "
          style="border-bottom: dashed; border-bottom-color: black"
      >
        <td class="item_cantidad_cont_print_invoice">1</td>
        <td class="info_print_invoice">Costo de envío</td>
        <td style="text-align: end; width: 20%">
          {{ parseFloat(customerOrder.delivery).toFixed(2) }}
        </td>
      </tr>
    </table>
    <div
        v-if="customerOrder.total !== customerOrder.total_coupon"
        class="costos_print_invoice"
    >
      <span>DESCUENTO: "{{ discount_title(customerOrder.coupon_title) }}"</span>
      <span>S/{{ descuento() }}</span>
    </div>
    <div class="costos_print_invoice">
      <span>OP. GRAVADAS</span>
      <span v-if="customerOrder.local.has_taxable_delivery"
      >S/{{ gravado_con_delivery().toFixed(2) }}</span
      >
      <span v-if="!customerOrder.local.has_taxable_delivery"
      >S/{{ gravado_sin_delivery().toFixed(2) }}</span
      >
    </div>
    <div class="costos_print_invoice">
      <span>IGV</span>
      <span v-if="customerOrder.local.has_taxable_delivery"
      >S/{{
          (total_con_delivery() - gravado_con_delivery()).toFixed(2)
        }}</span
      >
      <span v-if="!customerOrder.local.has_taxable_delivery"
      >S/{{
          (total_sin_delivery() - gravado_sin_delivery()).toFixed(2)
        }}</span
      >
    </div>
    <div
        v-if="parseFloat(customerOrder.commission) > 0"
        class="costos_print_invoice"
    >
      <span>Bolsa Compostable</span>
      <span>S/{{ parseFloat(customerOrder.commission).toFixed(2) }}</span>
    </div>
    <div class="costos_print_invoice" style="border-bottom: dashed">
      <span> IMPORTE TOTAL </span>
      <span v-if="customerOrder.local.has_taxable_delivery">
        S/{{ total_con_delivery().toFixed(2) }}
      </span>
      <span v-if="!customerOrder.local.has_taxable_delivery">
        S/{{ total_sin_delivery().toFixed(2) }}
      </span>
    </div>
    <div>
      {{ metodoPago(customerOrder) }}
    </div>
    <div v-if="customerOrder.name_user_order">
      Usuario:&nbsp;{{ customerOrder.name_user_order }}
    </div>
    <div
        style="display: flex; align-items: flex-start"
        v-if="
        customerOrder.phone_user_order != null &&
        customerOrder.phone_user_order !== ''
      "
    >
      Celular:&nbsp;{{ customerOrder.phone_user_order }}
    </div>
    <div v-if="customerOrder.type_delivery === '3'">
      Dirección de envío:&nbsp;{{ customerOrder.address }}
    </div>
    <div
        v-if="
        customerOrder.type_delivery === '3' &&
        parseFloat(customerOrder.delivery) > 0 &&
        !customerOrder.local.has_taxable_delivery
      "
    >
      Delivery: S/.&nbsp;{{ parseFloat(customerOrder.delivery).toFixed(2) }}
    </div>
    <div v-if="customerOrder.comments !== ''">
      Comentarios del cliente:&nbsp;{{ customerOrder.comments }}
    </div>
    <div v-if="customerOrder.type_delivery === '1'">
      Número de personas:&nbsp;{{ customerOrder.cant_people }}
    </div>
    <div v-if="customerOrder.change_time">
      Programado:&nbsp;{{ `${programacion(customerOrder.time_to_order)}` }}
    </div>
    <div v-if="!customerOrder.local.has_invoice_quickeat">
      {{ getNameInvoice(customerOrder.invoice_type) }}
    </div>
    <div
        v-if="
        !customerOrder.local.has_invoice_quickeat &&
        customerOrder.ruc != null &&
        customerOrder.ruc.toString() !== ''
      "
    >
      {{ getInvoiceAttributes(customerOrder.invoice_type, customerOrder.ruc) }}
    </div>
    <div
        v-if="
        !customerOrder.local.has_invoice_quickeat &&
        customerOrder.razon_social != null &&
        customerOrder.razon_social.toString() !== ''
      "
    >
      {{
        getInvoiceRazonSocial(
            customerOrder.invoice_type,
            customerOrder.razon_social
        )
      }}
    </div>
  </div>
</template>

<script setup>
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import { useStore } from "vuex";
import {defineEmits, onMounted, ref, computed, getCurrentInstance} from "vue";

const emits = defineEmits(['finish']);

const { appContext } = getCurrentInstance();
const toast = appContext.config.globalProperties.$toast;

const store = useStore();
const medio_de_pago_cliente = ref("");
const order_detail_temp_obj = ref([]);

const customerOrder = computed(() => store.state.customerOrder);
const orderDetails = computed(() => store.state.orderDetails);

/*
const subtotal = computed(() => {
  let suma = 0;
  for (let i = 0; i < orderDetails.value.length; i++) {
    suma = suma + parseFloat(orderDetails.value[i].subtotal);
  }
  return suma;
})
*/

function metodoPago(order) {
  switch (order.type_payment) {
    case 0:
      medio_de_pago_cliente.value = "Pagó en Línea";
      break;
    case 1:
      medio_de_pago_cliente.value = "Paga con S/. " + customerOrder.value.amount_pay_user;
      break;
    case 2:
      medio_de_pago_cliente.value = "Paga con POS Visa / Mastercard";
      break;
    case 3:
      medio_de_pago_cliente.value = "Paga con YAPE";
      break;
    case 4:
      medio_de_pago_cliente.value = "Paga con PLIN";
      break;
    case 5:
      medio_de_pago_cliente.value = order.card_type_pos_name;
  }
  return medio_de_pago_cliente.value;
}

function title_invoice() {
  switch (customerOrder.value.invoice_type) {
    case 0:
      return `Boleta de venta electrónica ${customerOrder.value.invoice_serial}-${customerOrder.value.invoice_number}`;
    case 1:
      return `Factura de venta electrónica ${customerOrder.value.invoice_serial}-${customerOrder.value.invoice_number}`;
    case 2:
      return `Nota de venta electrónica N001-${customerOrder.value.id}`;
  }
}

function customerInvoice() {
  if (customerOrder.value.invoice_type === 0) {
    if (customerOrder.value.ruc !== "" && customerOrder.value.ruc !== null) {
      return customerOrder.value.razon_social;
    } else {
      return customerOrder.value.name_user_order;
    }
  } else if (customerOrder.value.invoice_type === 1) {
    return customerOrder.value.razon_social;
  }
  return customerOrder.value.name_user_order;
}

function emitClose() {
  emits("finish");
}

function descuento() {
  return (
      parseFloat(customerOrder.value.total_coupon) -
      parseFloat(customerOrder.value.total)
  ).toFixed(2);
}

function gravado_con_delivery() {
  return (
      parseFloat(customerOrder.value.total_coupon) /
      (1 + parseFloat(customerOrder.value.local.tax_rate))
  );
}

function gravado_sin_delivery() {
  return (
      (parseFloat(customerOrder.value.total_coupon) -
          parseFloat(customerOrder.value.delivery)) /
      (1 + parseFloat(customerOrder.value.local.tax_rate))
  );
}

function total_con_delivery() {
  return parseFloat(customerOrder.value.total_coupon);
}

function total_sin_delivery() {
  return (
      parseFloat(customerOrder.value.total_coupon) -
      parseFloat(customerOrder.value.delivery)
  );
}

function discount_title(title) {
  if (title == null || title === "") {
    return "";
  } else {
    return title;
  }
}

function hora_pedido(date) {
  return moment(date).locale("es").format("DD/MM/YYYY hh:mm a");
}

function programacion(fecha) {
  let aux = moment(fecha).add("5", "hours");
  return aux.format("DD/MM hh:mm a");
}

function getNameInvoice(invoice_type) {
  switch (invoice_type) {
    case 0:
      return "Cliente solicitó boleta";
    case 1:
      return "Cliente solicitó factura";
    case 2:
      return "Nota de venta";
  }
}

function getInvoiceAttributes(invoice_type, ruc) {
  let string = "";
  switch (invoice_type) {
    case 0:
      string = "DNI: ";
      break;
    case 1:
      string = "RUC: ";
      break;
  }
  return string + ruc;
}

function getInvoiceRazonSocial(invoice_type, razon_social) {
  let string = "";
  switch (invoice_type) {
    case 0:
      string = "Nombre y apellido: ";
      break;
    case 1:
      string = "Razón social: ";
      break;
  }
  return string + razon_social;
}

onMounted(() => {
  order_detail_temp_obj.value = cloneDeep(orderDetails.value);
  for (let i = 0; i < order_detail_temp_obj.value.length; i++) {
    if (order_detail_temp_obj.value[i].order !== customerOrder.value.id) {
      toast.error("Hubo un error con la información del pedido");
      emitClose.value();
      return;
    }
    if (
        order_detail_temp_obj.value[i].product_size_names !== null &&
        order_detail_temp_obj.value[i].product_size_names !== ""
    ) {
      if (order_detail_temp_obj.value[i].product_size_names.includes("/")) {
        // El formato es BBQ (2)/ Ketchup (1)/ Mayonesa (2)
        order_detail_temp_obj.value[i].product_size_names = order_detail_temp_obj.value[i].product_size_names.split("/");
      } else {
        // El formato es BBQ,Ketchup,Mayonesa
        order_detail_temp_obj.value[i].product_size_names = order_detail_temp_obj.value[i].product_size_names.split(",");
      }
      order_detail_temp_obj.value[i].product_size_names = order_detail_temp_obj.value[i].product_size_names.map((elemento) =>
              elemento.trim()
          );
    }
    if (
        order_detail_temp_obj.value[i].product_sizes2_names !== null &&
        order_detail_temp_obj.value[i].product_sizes2_names !== ""
    ) {
      if (order_detail_temp_obj.value[i].product_sizes2_names.includes("/")) {
        // El formato es BBQ (2)/ Ketchup (1)/ Mayonesa (2)
        order_detail_temp_obj.value[i].product_sizes2_names = order_detail_temp_obj.value[i].product_sizes2_names.split("/");
      } else {
        // El formato es BBQ,Ketchup,Mayonesa
        order_detail_temp_obj.value[i].product_sizes2_names = order_detail_temp_obj.value[i].product_sizes2_names.split(",");
      }
      order_detail_temp_obj.value[i].product_sizes2_names = order_detail_temp_obj.value[i].product_sizes2_names.map((elemento) =>
              elemento.trim()
          );
    }
    if (
        order_detail_temp_obj.value[i].product_sizes3_names !== null &&
        order_detail_temp_obj.value[i].product_sizes3_names !== ""
    ) {
      if (order_detail_temp_obj.value[i].product_sizes3_names.includes("/")) {
        // El formato es BBQ (2)/ Ketchup (1)/ Mayonesa (2)
        order_detail_temp_obj.value[i].product_sizes3_names =
            order_detail_temp_obj.value[i].product_sizes3_names.split("/");
      } else {
        // El formato es BBQ,Ketchup,Mayonesa
        order_detail_temp_obj.value[i].product_sizes3_names =
            order_detail_temp_obj.value[i].product_sizes3_names.split(",");
      }
      order_detail_temp_obj.value[i].product_sizes3_names =
          order_detail_temp_obj.value[i].product_sizes3_names.map((elemento) =>
              elemento.trim()
          );
    }
    if (
        order_detail_temp_obj.value[i].product_sizes4_names !== null &&
        order_detail_temp_obj.value[i].product_sizes4_names !== ""
    ) {
      if (order_detail_temp_obj.value[i].product_sizes4_names.includes("/")) {
        // El formato es BBQ (2)/ Ketchup (1)/ Mayonesa (2)
        order_detail_temp_obj.value[i].product_sizes4_names =
            order_detail_temp_obj.value[i].product_sizes4_names.split("/");
      } else {
        // El formato es BBQ,Ketchup,Mayonesa
        order_detail_temp_obj.value[i].product_sizes4_names =
            order_detail_temp_obj.value[i].product_sizes4_names.split(",");
      }
      order_detail_temp_obj.value[i].product_sizes4_names =
          order_detail_temp_obj.value[i].product_sizes4_names.map((elemento) =>
              elemento.trim()
          );
    }
    if (order_detail_temp_obj.value[i].ingredients_add) {
      if (order_detail_temp_obj.value[i].ingredients_add.includes("/")) {
        order_detail_temp_obj.value[i].ingredients_add =
            order_detail_temp_obj.value[i].ingredients_add.split("/");
      } else if (
          order_detail_temp_obj.value[i].ingredients_add.includes(",")
      ) {
        order_detail_temp_obj.value[i].ingredients_add =
            order_detail_temp_obj.value[i].ingredients_add.split(",");
      } else {
        order_detail_temp_obj.value[i].ingredients_add =
            order_detail_temp_obj.value[i].ingredients_add.split("-");
      }
      order_detail_temp_obj.value[i].ingredients_add =
          order_detail_temp_obj.value[i].ingredients_add.map((elemento) =>
              elemento.trim()
          );
    }
  }
  setTimeout(() => {
    var original_height;
    original_height = document.getElementById("app").style.height;
    var original_width;
    original_width = document.getElementById("app").style.width;
    document.getElementById("app").style.width = "80mm";
    document.getElementById("app").style.overflow = "hidden";

    try {
      // Print for Safari browser
      document.execCommand("print", false, null);
    } catch {
      window.print();
    }
    setTimeout(() => {
      document.getElementById("app").style.height = original_height;
      document.getElementById("app").style.width = original_width;
      document.getElementById("app").style.minHeight = "100vh";
      document.getElementById("app").style.overflow = "unset";
    }, 150);

    emitClose();
  }, 350);
})
</script>

<style scoped>
@media print {
  .fondo_impresion_invoice {
    width: 100%;
    margin: 0;
    float: none;
  }
}
@page :left {
}
@page :right {
}

.invoice_title {
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.fondo_impresion_invoice {
  font-family: Lucida Sans Typewriter Two;
  font-weight: bold !important;
  height: auto;
  font-size: 12pt !important;
  background: #fff !important;
  color: #000 !important;
  line-height: 1.5;
}
.table_products_print_invoice {
  width: 100%;
}
.table_header_print_invoice {
  border-style: dashed;
  border-color: #000;
  font-size: 11pt;
}
.column_cantidad_print_invoice {
  text-align: center;
}
.column_subtotal_print_invoice {
  text-align: center;
}
.item_cantidad_cont_print_invoice {
  text-align: center;
  border-right: dashed;
}
.costos_print_invoice {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.info_print_invoice {
  display: flex;
  flex-direction: column;
  margin-left: 2px;
  line-height: 1.2;
}
</style>