<template>
  <div class="fondo_impresion">
    <div>Orden: {{ customerOrder.id }}</div>
    <div>Fecha: {{ hora_pedido(customerOrder.order_date) }}</div>
    <table>
      <tr class="table_header_print">
        <th>Cant.</th>
        <th>Producto</th>
      </tr>
      <tr
        v-for="(item, key) in order_detail_temp_obj"
        :key="key"
        style="border-bottom: dashed; border-bottom-color: black"
      >
        <td style="width: 10%; border-right: dashed">
          <div class="item_cantidad_cont_print">{{ item.cant }}</div>
        </td>
        <td style="width: 90%">
          <div class="info_print">
            <div>
              <p style="margin-bottom: 0">
                {{ item.product_name }}
              </p>
              <ul style="margin-bottom: 0">
                <li v-for="size in item.product_size_names" :key="size">
                  a: {{ size }}
                </li>
                <li
                  v-if="
                    item.product_sizes2_names !== null &&
                    item.product_sizes2_names.length > 0
                  "
                >
                  <ul>
                    <li v-for="size in item.product_sizes2_names" :key="size">
                      b: {{ size }}
                    </li>
                  </ul>
                </li>
                <li
                  v-if="
                    item.product_sizes3_names !== null &&
                    item.product_sizes3_names.length > 0
                  "
                >
                  <ul>
                    <li v-for="size in item.product_sizes3_names" :key="size">
                      c: {{ size }}
                    </li>
                  </ul>
                </li>
                <li
                  v-if="
                    item.product_sizes4_names !== null &&
                    item.product_sizes4_names.length > 0
                  "
                >
                  <ul>
                    <li v-for="size in item.product_sizes4_names" :key="size">
                      d: {{ size }}
                    </li>
                  </ul>
                </li>
                <li v-if="item.ingredients_add.length > 0 && item.product_id !== 0">
                  Extras:
                  <ul>
                    <li
                      v-for="ingredient in item.ingredients_add"
                      :key="ingredient"
                    >
                      - {{ ingredient }}
                    </li>
                  </ul>
                </li>
              </ul>
              <p style="margin-bottom: 0" v-if="item.comments">
                Obs: {{ item.comments }}
              </p>
            </div>
          </div>
        </td>
      </tr>
    </table>
    <div>Cliente:&nbsp;{{ customerOrder.name_user_order }}</div>
    <div
      v-if="
        customerOrder.user_call_center != null ||
        customerOrder.user !== null ||
        customerOrder.phone_user_order.length > 0
      "
    >
      Celular:&nbsp;{{ customerOrder.phone_user_order }}
    </div>
    <div v-if="customerOrder.comments !== ''">
      Comentarios:&nbsp;{{ customerOrder.comments }}
    </div>
    <div v-if="customerOrder.type_delivery === '1'">
      Número de personas:&nbsp;{{ customerOrder.cant_people }}
    </div>
    <div>Canal: {{ canal_pedido(customerOrder.type_delivery) }}</div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import {defineEmits, getCurrentInstance, onMounted, ref, computed} from "vue";

const emits = defineEmits(['finish']);

const { appContext } = getCurrentInstance();
const toast = appContext.config.globalProperties.$toast;

const store = useStore();
const order_detail_temp_obj = ref([]);

const customerOrder = computed(() => store.state.customerOrder);
const orderDetails = computed(() => store.state.orderDetails);

function emitClose() {
  emits("finish");
}

function hora_pedido(date) {
  return moment(date).locale("es").format("DD/MM/YYYY hh:mm a");
}

function canal_pedido(type_delivery) {
  switch (type_delivery) {
    case "1":
      return "Salón";
    case "2":
      return "Recojo";
    case "3":
      return "Delivery";
    case "4":
      return "Venta rápida";
    default:
      return "Externo";
  }
}

onMounted(() => {
  order_detail_temp_obj.value = cloneDeep(orderDetails.value);
  for (let i = 0; i < order_detail_temp_obj.value.length; i++) {
    if (order_detail_temp_obj.value[i].order !== customerOrder.value.id) {
      toast.error("Hubo un error con la información del pedido");
      emitClose();
      return;
    }
    if (
        order_detail_temp_obj.value[i].product_size_names !== null &&
        order_detail_temp_obj.value[i].product_size_names !== ""
    ) {
      if (order_detail_temp_obj.value[i].product_size_names.includes("/")) {
        // El formato es BBQ (2)/ Ketchup (1)/ Mayonesa (2)
        order_detail_temp_obj.value[i].product_size_names = order_detail_temp_obj.value[i].product_size_names.split("/");
      } else {
        // El formato es BBQ,Ketchup,Mayonesa
        order_detail_temp_obj.value[i].product_size_names = order_detail_temp_obj.value[i].product_size_names.split(",");
      }
      order_detail_temp_obj.value[i].product_size_names = order_detail_temp_obj.value[i].product_size_names.map((elemento) =>
              elemento.trim()
          );
    }
    if (
        order_detail_temp_obj.value[i].product_sizes2_names !== null &&
        order_detail_temp_obj.value[i].product_sizes2_names !== ""
    ) {
      if (order_detail_temp_obj.value[i].product_sizes2_names.includes("/")) {
        // El formato es BBQ (2)/ Ketchup (1)/ Mayonesa (2)
        order_detail_temp_obj.value[i].product_sizes2_names = order_detail_temp_obj.value[i].product_sizes2_names.split("/");
      } else {
        // El formato es BBQ,Ketchup,Mayonesa
        order_detail_temp_obj.value[i].product_sizes2_names = order_detail_temp_obj.value[i].product_sizes2_names.split(",");
      }
      order_detail_temp_obj.value[i].product_sizes2_names = order_detail_temp_obj.value[i].product_sizes2_names.map((elemento) =>
              elemento.trim()
          );
    }
    if (
        order_detail_temp_obj.value[i].product_sizes3_names !== null &&
        order_detail_temp_obj.value[i].product_sizes3_names !== ""
    ) {
      if (order_detail_temp_obj.value[i].product_sizes3_names.includes("/")) {
        // El formato es BBQ (2)/ Ketchup (1)/ Mayonesa (2)
        order_detail_temp_obj.value[i].product_sizes3_names = order_detail_temp_obj.value[i].product_sizes3_names.split("/");
      } else {
        // El formato es BBQ,Ketchup,Mayonesa
        order_detail_temp_obj.value[i].product_sizes3_names = order_detail_temp_obj.value[i].product_sizes3_names.split(",");
      }
      order_detail_temp_obj.value[i].product_sizes3_names = order_detail_temp_obj.value[i].product_sizes3_names.map((elemento) =>
              elemento.trim()
          );
    }
    if (
        order_detail_temp_obj.value[i].product_sizes4_names !== null &&
        order_detail_temp_obj.value[i].product_sizes4_names !== ""
    ) {
      if (order_detail_temp_obj.value[i].product_sizes4_names.includes("/")) {
        // El formato es BBQ (2)/ Ketchup (1)/ Mayonesa (2)
        order_detail_temp_obj.value[i].product_sizes4_names = order_detail_temp_obj.value[i].product_sizes4_names.split("/");
      } else {
        // El formato es BBQ,Ketchup,Mayonesa
        order_detail_temp_obj.value[i].product_sizes4_names = order_detail_temp_obj.value[i].product_sizes4_names.split(",");
      }
      order_detail_temp_obj.value[i].product_sizes4_names = order_detail_temp_obj.value[i].product_sizes4_names.map((elemento) =>
              elemento.trim()
          );
    }
    if (order_detail_temp_obj.value[i].ingredients_add) {
      if (order_detail_temp_obj.value[i].ingredients_add.includes("/")) {
        order_detail_temp_obj.value[i].ingredients_add = order_detail_temp_obj.value[i].ingredients_add.split("/");
      } else if (
          order_detail_temp_obj.value[i].ingredients_add.includes(",")
      ) {
        order_detail_temp_obj.value[i].ingredients_add = order_detail_temp_obj.value[i].ingredients_add.split(",");
      } else {
        order_detail_temp_obj.value[i].ingredients_add = order_detail_temp_obj.value[i].ingredients_add.split("-");
      }
      order_detail_temp_obj.value[i].ingredients_add = order_detail_temp_obj.value[i].ingredients_add.map((elemento) =>
              elemento.trim()
          );
    }
  }

  setTimeout(() => {
    var original_height;
    original_height = document.getElementById("app").style.height;
    var original_width;
    original_width = document.getElementById("app").style.width;
    document.getElementById("app").style.width = "80mm";
    document.getElementById("app").style.overflow = "hidden";

    try {
      // Print for Safari browser
      document.execCommand("print", false, null);
    } catch {
      window.print();
    }
    setTimeout(() => {
      document.getElementById("app").style.height = original_height;
      document.getElementById("app").style.width = original_width;
      document.getElementById("app").style.minHeight = "100vh";
      document.getElementById("app").style.overflow = "unset";
    }, 150);

    emitClose();
  }, 350);
})
</script>

<style scoped>
@media print {
  .fondo_impresion {
    width: 100%;
    margin: 0;
    float: none;
  }
}
@page :left {
}
@page :right {
}

.fondo_impresion {
  font-family: Lucida Sans Typewriter Two;
  font-weight: bold !important;
  height: auto;
  font-size: 13pt !important;
  background: #fff !important;
  color: #000 !important;
}
.table_header_print {
  border-style: dashed;
  border-color: #000;
  font-size: 12pt;
}
.item_cantidad_cont_print {
  text-align: center;
}
.info_print {
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  margin-left: 5px;
}
</style>
